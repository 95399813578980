import { adminRoutePrefix, shopUrlPropertyName } from "@smartrr/shared/constants";
import { shopifyGidToNumber } from "@smartrr/shared/utils/ensureShopifyGid";
import { ParsedQs, stringify } from "qs";

import { router } from "@vendor-app/router";

import { getShopUrlFromGlobals } from "./shopify/getShopUrlFromGlobals";

export function navigateWithShopInQuery(
  to: string,
  query: ParsedQs = {},
  options?: { replace: boolean; hash?: string },
  newWindow?: boolean
) {
  const shop = getShopUrlFromGlobals();

  if (shop && !query[shopUrlPropertyName]) {
    query = { ...query, [shopUrlPropertyName]: shop };
  }

  if (newWindow) {
    window.open(`${to}${stringify(query, { addQueryPrefix: true })}`);
    return;
  }

  router.navigate({ to, search: query, replace: options?.replace, hash: options?.hash });
}

export function navigateToSubscriptionDetails(shopifySubscriptionId: string, newTab?: boolean) {
  return navigateWithShopInQuery(
    `${adminRoutePrefix}/subscriptions/${shopifyGidToNumber(shopifySubscriptionId).toString()}`,
    {},
    undefined,
    newTab
  );
}

export function navigateToCustomerDetails(shopifyCustomerId: string, newTab?: boolean) {
  return navigateWithShopInQuery(
    `${adminRoutePrefix}/customers/${shopifyGidToNumber(shopifyCustomerId).toString()}`,
    {},
    undefined,
    newTab
  );
}
